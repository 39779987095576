import React, { useEffect, useState } from 'react'
import { Table, Tag, DatePicker, Modal } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';

import { sendNotify } from '../helper/notification'
// import { downloadAsCSV } from '../helper/download';
import { calculateTime } from '../helper/timeCalculate'

import TrackRep from '../repository/TrackRep'

const { RangePicker } = DatePicker;

const { confirm } = Modal;
export default function Track() {
    const navigate = useNavigate();

    const [trackLoading, setTrackLoading] = useState(false)
    const [trackList, setTrackList] = useState([])
    const [searchID, setSearchID] = useState('')

    useEffect(() => {
        getTracks()
    }, []);

    const openTrack = (id) => {
        navigate(`/app/process/${id}`)
    }

    const formatBase64 = (file, mimeType) => {
        return `data:${mimeType};base64,${file}`;
    };

    // Function to infer MIME type based on content or another way if not provided in the name
    const inferMimeType = (base64String) => {
        console.log(base64String, "base64String")
        // This is a basic example, more comprehensive checks or mapping might be needed
        if (base64String.startsWith('/9j')) {
            return 'image/jpeg';
        } else if (base64String.startsWith('iVBORw0KGg')) {
            return 'image/png';
        } else if (base64String.startsWith('JVBER')) {
            return 'application/pdf';
        } else if (base64String.startsWith('UEsDB')) {
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        } else if (base64String.startsWith('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else {
            return 'application/octet-stream';
        }
    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'key',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Category',
            dataIndex: 'category',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'File',
            dataIndex: 'file',
            render: (record) => {
                return (
                    <div >
                        View {record.name}
                    </div >
                );
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (record) => {
                const mimeType = inferMimeType(record.file);
                console.log(record.file, mimeType, "record")
                return (
                    <div key={record._id} className="d-flex">
                        <Link
                            to="/app/view"
                            state={{ file: record.file, mimeType: mimeType }}
                        >
                            <button className="primary-btn" key={record._id} ><i className="far fa-eye"></i> View </button>
                        </Link>
                        <button className="primary-btn btn-red ms-3" title='Delete User' onClick={() => confirmDelete(record?._id)}><i className="fas fa-trash-alt"></i> Delete</button>

                    </div >
                );
            }
        },
    ];

    const confirmDelete = (id) => {
        confirm({
            title: <h5>Confirm Delete</h5>,
            content: <p>Are you sure you want to delete this?</p>,
            className: 'custom-confirm',
            centered: true,
            type: 'warning',
            okText: 'Delete',
            okButtonProps: { className: 'btn-red' },
            onOk() {
                deleteTrack(id);
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };

    const deleteTrack = (id) => {
        TrackRep.deleteTrack(id).then(response => {
            if (response) {
                if (response?.error) {
                    sendNotify('error', response.message)
                } else {
                    sendNotify('success', response.message)
                    getTracks()
                }
            }
        }).catch(error => {
            sendNotify('error', error)
        })
    }



    const convertToTable = (rawData) => {
        console.log(rawData, "rawData")
        let tableData = rawData?.map((data, i) => (
            {
                key: i + 1,
                date: moment(data?.createdAt).format("llll"),
                name: data?.name,
                category: data?.category,
                file: data,
                actions: data
            }
        ))
        return tableData
    }


    const getTracks = () => {
        setTrackLoading(true)
        TrackRep.getTracks().then(response => {
            setTrackLoading(false)
            if (response) {
                if (response?.error) {
                    sendNotify('error', "No Data Available")
                    setTrackList([])
                } else {
                    console.log(response?.data, "response?.dataresponse?.data")
                    let data = convertToTable(response?.data)
                    setTrackList(data)
                }
            }
        }).catch(error => {
            sendNotify('error', 'Failed to fetch Data')
            setTrackLoading(false)
        })
    }

    const searchTrack = (searchID) => {
        if (searchID) {
            setTrackLoading(true)
            TrackRep.searchTrack(searchID).then(response => {
                setTrackLoading(false)
                if (response) {
                    if (response?.error) {
                        sendNotify('error', response?.message)
                        setTrackList([])
                    } else {
                        // sendNotify('success', 'Fetch track Data')
                        let data = convertToTable(response?.data)
                        setTrackList(data)
                    }
                }
            }).catch(error => {
                sendNotify('error', 'No matched track id found')
                setTrackLoading(false)
            })
        } else {
            getTracks()
        }
    }

    const onSearchClear = () => {
        setSearchID('')
        searchTrack('')
    }

    const onChange = (dates, dateString) => {
        if (dates) {
            let payload = [
                dateString[0] + 'T00:00:00.002Z',
                dateString[1] + 'T23:59:59.002Z',
            ]
            TrackRep.filterByDateRange(payload).then(response => {
                if (response) {
                    if (response?.error) {
                        sendNotify('error', response.message)
                        setTrackList([])
                    } else {
                        if (response?.data?.length > 0) {
                            let data = convertToTable(response?.data)
                            setTrackList(data)
                        }
                        else {
                            sendNotify('error', "No track data was found for this date range.")
                            setTrackList([])
                        }
                    }
                }
            }).catch(error => {
                sendNotify('error', 'Failed to fetch Data')
            })
        } else {
            getTracks()
        }
    };

    const exportAsCSV = () => {
        // downloadAsCSV(excelList, 'track-data')
    }



    return (
        <section className='mt-md-3'>
            <div className="page-header flex-md-row flex-column">
                <h1>Documents</h1>
                {/* <div className='d-flex align-items-center'>
                    <RangePicker className='custom-datepicker' onChange={onChange} />
                    <button className={`primary-btn ms-3 py-11 ${trackList.length > 0 ? "" : "btn-disabled"} `} onClick={exportAsCSV}><i className="far fa-file-download"></i> Download</button>
                    <div className="custom-search mt-3 my-md-1 ms-3">
                        <div className='search-input-wrapper'>
                            <input type="text" placeholder='Search by track code' value={searchID} onChange={(e) => setSearchID(e.target.value)} />
                            <button className={`clear-btn ${searchID ? 'active' : ''}`} onClick={onSearchClear}><i className="fal fa-times-circle"></i></button>
                        </div>
                        <button onClick={() => searchTrack(searchID)}><i className="far fa-search"></i></button>
                    </div>
                </div> */}
            </div>

            <Table columns={columns} dataSource={trackList} loading={trackLoading} pagination={{ className: 'custom-pagination' }} className='custom-table' />
        </section>
    )
}
