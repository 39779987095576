import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <p>Copyright © WorkShiftly</p>
    </footer>
  )
}
