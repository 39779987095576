// Auth
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";

//Private
import Track from "../pages/track";
import Process from "../pages/process";
import Reports from "../pages/reports";
import Settings from "../pages/settings";
import FraudAssessment from "../pages/fraud-assessment";
import WorldCheck from "../pages/world-check";
import Accounts from "../pages/accounts";
import NotFound from "../pages/not-found";
import Tracks from "../pages/tracks"
import Upload from "../pages/upload";
import FileView from "../pages/file-view";

const routes = [
  {
    name: "Login",
    path: "/",
    icon: "",
    component: <Login />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Login",
    path: "/login",
    icon: "",
    component: <Login />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Register",
    path: "/register",
    icon: "",
    component: <Register />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  // {
  //   name: "Track",
  //   path: "track",
  //   icon: "fa-file-search",
  //   component: <Track />,
  //   type: 'private',
  //   permissions: ['analyst'],
  //   showInMenu: true,
  // },
  {
    name: "Documents",
    path: "tracks",
    icon: "fa-file-search",
    component: <Tracks />,
    type: 'private',
    permissions: ['admin', 'user'],
    showInMenu: true,
  },
  {
    path: "view",
    type: 'private',
    component: <FileView />,
    permissions: ['admin', 'user'],
    showInMenu: false,
  },
  {
    name: "Doc Upload",
    path: "upload",
    icon: "fa-file-upload",
    component: <Upload />,
    type: 'private',
    permissions: ['admin', 'user'],
    showInMenu: true,
  },
  {
    name: "Process",
    path: "process/:id",
    icon: "fa-file-search",
    component: <Process />,
    type: 'private',
    permissions: ['admin'],
    showInMenu: false,
  },
  {
    name: "Reports",
    path: "reports",
    icon: "fa-chart-bar",
    component: <Reports />,
    type: 'private',
    permissions: ['analyst'],
    showInMenu: false,
  },
  // {
  //   name: "Settings",
  //   path: "settings",
  //   icon: "fa-cog",
  //   component: <Settings />,
  //   type: 'private',
  //   permissions: ['admin'],
  //   showInMenu: true,
  // },
  {
    name: "Fraud Assessment",
    path: "fraud-assessment/:id",
    component: <FraudAssessment />,
    type: 'private',
    permissions: ['analyst', 'admin'],
  },
  {
    name: "World Check",
    path: "world-check/:id",
    component: <WorldCheck />,
    type: 'private',
    permissions: ['analyst', 'admin'],
  },
  {
    name: "Account",
    path: "account",
    icon: "fa-user-shield",
    component: <Accounts />,
    type: 'private',
    permissions: ['admin'],
    showInMenu: true,
  },

  {
    name: "Error 404",
    path: "*",
    icon: "",
    component: <NotFound />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  }
];

export default routes;
