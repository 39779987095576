import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'

import routes from "./routes";

const PrivateRoute = () => {
    let { isLogged, userDetails } = useSelector((state) => state.auth)
    let location = useLocation();
    let path = location.pathname.split('/')[2]
    let access = routes?.find(r => r.path.split('/')[0] === path)?.permissions?.find(r => r === userDetails?.role) ? true : false
    console.log(isLogged, access, userDetails, "accessaccessaccess")

    return (
        isLogged ?
            access ?
                <Outlet /> :
                <h2>Access Denied</h2> :
            <Navigate to="/login" />
    )
}

export default PrivateRoute;